<template>  
  <v-row align="center" justify="center">
    <v-col cols="12" align="center" justify="center">
      <div class="video-panel" id="video-panel"></div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "claraIframe",
  data() {
    return {      
      meetingCode: "",
      role: "",
      token: "",
      noOfParticipants: 0,
      meetingObj: {},
    };
  },
  computed: {
    ...mapGetters(["userProfile"]),
  },
  methods: {
    createMeeting() {      
      const domain = this.meetingObj.meetingUrl;
      const options = {
        roomName: this.meetingObj.meetingCode + "?role=" + this.role + "&jwt=" + this.token,
        width: 1250,
        height: window.innerHeight - 100,
        parentNode: document.querySelector("#video-panel"),
        interfaceConfigOverwrite: {},
        userInfo: {
          email: this.userProfile.data().email,
          displayName: this.userProfile.data().name,
        },
      };
      window.JitsiMeetExternalAPI =
        window.JitsiMeetExternalAPI || window.exports.JitsiMeetExternalAPI;
      const api = new window.JitsiMeetExternalAPI(domain, options);
      api.addListener("participantLeft", () => {
        this.noOfParticipants = api.getNumberOfParticipants();
        console.log("NO OF PARTICIPANTS:", this.noOfParticipants);
        if (this.noOfParticipants === 1) {
          this.$router.push({ path: "/" });
        }
      });

      api.addListener("participantJoined", () => {
        this.noOfParticipants = api.getNumberOfParticipants();
        console.log("Total number of participant", this.noOfParticipants);
        if (this.noOfParticipants >= 1) {
          console.log("Total number of participant speech deleted");
        }
      });
      console.log(api);
    },
    initMeeting() {
      this.meetingCode = this.$router.currentRoute.params.id;      
      this.$store
        .dispatch("fetchMeeting", this.meetingCode)
        .then((response) => {
          this.meetingObj = response;
          this.findMyRole().then((result) => {
            if (result) {              
              this.createMeeting();
            } else {
              console.log("cannot find any role");
            }
          });          
        });
    },
    findMyRole() {
      return new Promise((response, reject) => {
        if (this.meetingObj.interpreterList.includes(this.userProfile.id)) {
          this.role = "interpreter";
          this.token = this.meetingObj.guestToken;
          response(true);
        } else if (
          this.meetingObj.participantList.includes(this.userProfile.id)
        ) {
          this.role = "participant";
          this.token = this.meetingObj.guestToken;
          response(true);
        } else if (
          this.meetingObj.moderatorList.includes(this.userProfile.id)
        ) {
          this.role = "moderator";
          this.token = this.meetingObj.moderatorToken;
          response(true);
        }
        reject(false);
      });
    },
  },
  mounted() {
    this.initMeeting();
  },
};
</script>


// interfaceConfigOverwrite: {
//                     filmStripOnly: false,
//                     TOOLBAR_BUTTONS: [],
//                     TOOLBAR_ALWAYS_VISIBLE: false,
//                     HIDE_INVITE_MORE_HEADER: true,
//                     DISABLE_VIDEO_BACKGROUND: true,
//                     DEFAULT_BACKGROUND: '#FFF',
//                 },